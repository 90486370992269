function GridEventRenderer() {
	var t = this;

	// exports
	t.renderEvents = renderEvents;
	t.clearEvents = clearEvents;
	t.draggableDayEvent = draggableDayEvent; // made public so that subclasses can override
	t.nonDraggableDayEvent = nonDraggableDayEvent; // made public so that subclasses can override
	t.resizableDayEvent = resizableDayEvent; // "

	t.clientSegments = function () {
		return getClientSegments(clientSegments);
	};

	// imports
	var opt = t.opt;
	var trigger = t.trigger;
	var isPreventEventMovement = t.isPreventEventMovement;
	var isEventDraggable = t.isEventDraggable;
	var isEventResizable = t.isEventResizable;
	var reportEventElement = t.reportEventElement;
	var eventElementHandlers = t.eventElementHandlers;
	var showEvents = t.showEvents;
	var hideEvents = t.hideEvents;
	var eventDrop = t.eventDrop;
	var eventCancelDrop = t.eventCancelDrop;
	var eventResize = t.eventResize;
	var getRowCnt = t.getRowCnt;
	var getColCnt = t.getColCnt;
	var allDayRow = t.allDayRow; // TODO: rename
	var colLeft = t.colLeft;
	var colRight = t.colRight;
	var colContentLeft = t.colContentLeft;
	var colContentRight = t.colContentRight;
	var getDaySegmentContainer = t.getDaySegmentContainer;
	var renderDayOverlay = t.renderDayOverlay;
	var clearOverlays = t.clearOverlays;
	var clearSelection = t.clearSelection;
	var getHoverListener = t.getHoverListener;
	var resourceAgendaGridRangeToSegments = t.resourceAgendaGridRangeToSegments;
	var cellToTime = t.cellToTime;
	var cellToCellOffset = t.cellToCellOffset;
	var cellOffsetToDayOffset = t.cellOffsetToDayOffset;
	var dateToTimeOffset = t.dateToTimeOffset;
	var dayOffsetToCellOffset = t.dayOffsetToCellOffset;
	var calendar = t.calendar;
	var getEventEnd = calendar.getEventEnd;
	var formatDate = calendar.formatDate;

	//Customized for seedcode
	var compressedView = opt('compressedView');
	var fluidMonths = opt('fluidMonths');
	var maxAllDayEvents = opt('maxAllDayEvents');
	var manageClone = t.manageClone;

	var clientSegments;

	// Render `events` onto the calendar, attach mouse event handlers, and call the `eventAfterRender` callback for each.
	// Mouse event will be lazily applied, except if the event has an ID of `modifiedEventId`.
	// Can only be called when the event container is empty (because it wipes out all innerHTML).
	function renderEvents(events, modifiedEventId) {
		// do the actual rendering. Receive the intermediate "segment" data structures.
		var segments = _renderDayEvents(
			events,
			false, // don't append event elements
			true // set the heights of the rows
		);

		clientSegments = segments;

		// report the elements to the View, for general drag/resize utilities
		segmentElementEach(segments, function (segment, element) {
			reportEventElement(segment.event, element);
		});

		// attach mouse handlers
		attachHandlers(segments, modifiedEventId);

		// call `eventAfterRender` callback for each event
		segmentElementEach(segments, function (segment, element) {
			trigger('eventAfterRender', segment.event, segment.event, element);
		});
	}

	function clearEvents() {
		t.getDaySegmentContainer().empty();
	}

	// Render an event on the calendar, but don't report them anywhere, and don't attach mouse handlers.
	// Append this event element to the event container, which might already be populated with events.
	// If an event's segment will have row equal to `adjustRow`, then explicitly set its top coordinate to `adjustTop`.
	// This hack is used to maintain continuity when user is manually resizing an event.
	// Returns an array of DOM elements for the event.
	function renderTempDayEvent(event, adjustRow, adjustTop) {
		// actually render the event. `true` for appending element to container.
		// Recieve the intermediate "segment" data structures.
		var segments = _renderDayEvents(
			[event],
			true, // append event elements
			false // don't set the heights of the rows - Customized for seedcode so we don't adjust rows if we are constraining month view.
		);

		var elements = [];

		// Adjust certain elements' top coordinates
		segmentElementEach(segments, function (segment, element) {
			if (segment.row === adjustRow) {
				element.css('top', adjustTop);
			}
			elements.push(element[0]); // accumulate DOM nodes
		});

		return elements;
	}

	// Render events onto the calendar. Only responsible for the VISUAL aspect.
	// Not responsible for attaching handlers or calling callbacks.
	// Set `doAppend` to `true` for rendering elements without clearing the existing container.
	// Set `doRowHeights` to allow setting the height of each row, to compensate for vertical event overflow.
	function _renderDayEvents(events, doAppend, doRowHeights) {
		seedcoder.logSplitTime('received events');
		// where the DOM nodes will eventually end up
		var finalContainer = getDaySegmentContainer();

		// the container where the initial HTML will be rendered.
		// If `doAppend`==true, uses a temporary container.
		var renderContainer = doAppend ? $('<div/>') : finalContainer;

		var segments = buildSegments(events);
		seedcoder.logSplitTime('render segments');
		var html;
		var elements;
		var moreEventsHTML;
		var removedElements = [];

		// calculate the desired `left` and `width` properties on each segment object
		calculateHorizontals(segments);
		seedcoder.logSplitTime('calculate horizontals');

		// build the HTML string. relies on `left` property
		html = buildHTML(segments);
		seedcoder.logSplitTime('build htmnl');

		// render the HTML. innerHTML is considerably faster than jQuery's .html()
		renderContainer[0].innerHTML = html;
		seedcoder.logSplitTime('render container');

		// retrieve the individual elements
		elements = renderContainer.children();
		seedcoder.logSplitTime('render children');

		// if we were appending, and thus using a temporary container,
		// re-attach elements to the real container.
		if (doAppend) {
			finalContainer.append(elements); //This is no longer necessary becasue we are removing dom elements and attaching them to the final container regardless. It doesn't seem to affect performance at all though.
		}
		seedcoder.logSplitTime('append elements');
		// assigns each element to `segment.event`, after filtering them through user callbacks
		resolveElements(segments, elements);
		seedcoder.logSplitTime('resolve segments');
		// Calculate the left and right padding+margin for each element.
		// We need this for setting each element's desired outer width, because of the W3C box model.
		// It's important we do this in a separate pass from acually setting the width on the DOM elements
		// because alternating reading/writing dimensions causes reflow for every iteration.
		segmentElementEach(segments, function (segment, element) {
			segment.hsides = hsides(element, true); // include margins = `true`
		});
		seedcoder.logSplitTime('hsideds');

		//Set the width of each element - Customized for SeedCode. We are removeing the elements from the dom, then performing our css transformations and adding all elements back at once to minimize reflows.
		segmentElementEach(segments, function (segment, element) {
			// var currentElement = element.detach();

			element.width(Math.max(0, segment.outerWidth - segment.hsides));

			// removedElements.push(currentElement);
		});

		// finalContainer.html(removedElements);

		seedcoder.logSplitTime('set widths');

		// Grab each element's outerHeight (setVerticals uses this).
		// To get an accurate reading, it's important to have each element's width explicitly set already.
		segmentElementEach(segments, function (segment, element) {
			segment.outerHeight = element.outerHeight(true); // include margins = `true`
		});

		// Set the top coordinate on each element (requires segment.outerHeight)
		setVerticals(segments, doRowHeights);

		seedcoder.logSplitTime('set heights');

		return segments;
	}

	// Generate an array of "segments" for all events.
	function buildSegments(events) {
		var eventShownFunc = opt('eventShown');
		var segments = [];
		for (var i = 0; i < events.length; i++) {
			var beforeRenderResult = trigger(
				'beforeEventRender',
				events[i],
				events[i],
				events
			);
			if (beforeRenderResult) {
				continue;
			}
			if (
				events[i].allDay ||
				!eventShownFunc(events[i]) ||
				events[i].unscheduled ||
				events[i].isMeasureOnly ||
				events[i].isMapOnly
			) {
				continue;
			}
			var eventSegments = buildSegmentsForEvent(events[i]);
			segments.push.apply(segments, eventSegments); // append an array to an array
		}
		seedcoder.logSplitTime('generate segments');
		return segments;
	}

	// Generate an array of segments for a single event.
	// A "segment" is the same data structure that View.resourceGridRangeToSegments produces,
	// with the addition of the `event` property being set to reference the original event.
	function buildSegmentsForEvent(event) {
		var segments = resourceAgendaGridRangeToSegments(
			event.start,
			getEventEnd(event),
			true,
			event.resource
		);
		for (var i = 0; i < segments.length; i++) {
			segments[i].event = event;
		}

		return segments;
	}

	// Sets the `left` and `outerWidth` property of each segment.
	// These values are the desired dimensions for the eventual DOM elements.
	function calculateHorizontals(segments) {
		var isRTL = opt('isRTL');
		for (var i = 0; i < segments.length; i++) {
			var segment = segments[i];

			// Determine functions used for calulating the elements left/right coordinates,
			// depending on whether the view is RTL or not.
			// NOTE:
			// colLeft/colRight returns the coordinate butting up the edge of the cell.
			// colContentLeft/colContentRight is indented a little bit from the edge.
			var leftFunc = (isRTL ? segment.isEnd : segment.isStart)
				? colContentLeft
				: colLeft;
			var rightFunc = (isRTL ? segment.isStart : segment.isEnd)
				? colContentRight
				: colRight;

			var left = leftFunc(segment.leftCol);
			var right = rightFunc(segment.rightCol);

			if (
				segments[i].event.isDayback ||
				segments[i].event.isUnavailable
			) {
				segment.left = left - 2;
				segment.outerWidth = right - left + 2 * 2;
			} else {
				segment.left = left;
				segment.outerWidth = right - left;
			}
		}
	}

	// Build a concatenated HTML string for an array of segments
	function buildHTML(segments) {
		var html = '';
		for (var i = 0; i < segments.length; i++) {
			html += buildHTMLForSegment(segments[i]);
		}
		return html;
	}

	// Build an HTML string for a single segment.
	// Relies on the following properties:
	// - `segment.event` (from `buildSegmentsForEvent`)
	// - `segment.left` (from `calculateHorizontals`)
	function buildHTMLForSegment(segment) {
		var html = '';
		var isRTL = opt('isRTL');
		var event = segment.event;
		var url = event.url;

		//Customized for seedcode
		var addClass =
			(!fluidMonths && t.name === 'month') || compressedView
				? 'event-constrain'
				: '';

		// generate the list of CSS classNames
		var classNames = ['fc-event', 'fc-event-hori'];

		if (isEventDraggable(event)) {
			classNames.push('fc-event-draggable');
		}
		if (event.isUnavailable) {
			classNames.push('fc-event-unavailable');
		}
		if (event.isDayback) {
			classNames.push('fc-event-dayback');
		}
		if (segment.isStart) {
			classNames.push('fc-event-start');
		}
		if (segment.isEnd) {
			classNames.push('fc-event-end');
		}
		// use the event's configured classNames
		// guaranteed to be an array via `buildEvent`
		classNames = classNames.concat(event.className);
		if (event.source) {
			// use the event's source's classNames, if specified
			classNames = classNames.concat(event.source.className || []);
		}

		// generate a semicolon delimited CSS string for any of the "skin" properties
		// of the event object (`backgroundColor`, `borderColor` and such)
		var skinCss = getSkinCss(event, opt);

		if (url) {
			html += "<a href='" + htmlEscape(url) + "'";
		} else {
			html += '<div';
		}
		html +=
			" data-id='" +
			event._id +
			"'" +
			" data-instance='" +
			segment.instance +
			"'" +
			" class='" +
			classNames.join(' ') +
			"'" +
			' style=' +
			"'" +
			'position:absolute;' +
			'left:' +
			segment.left +
			'px;' +
			skinCss +
			"'" +
			'>';
		if (
			!event.allDay &&
			segment.isStart &&
			isEventResizable(event) &&
			!event.isUnavailable
		) {
			html +=
				"<div class='ui-resizable-handle ui-resizable-w" +
				"'>" +
				'&nbsp;&nbsp;&nbsp;' + // makes hit area a lot better for IE6/7
				'</div>';
		}
		html += "<div class='fc-event-inner " + addClass + "'>";
		if (!event.allDay && segment.isStart && !event.isUnavailable) {
			html +=
				"<span class='fc-event-time time'>" +
				htmlEscape(formatDate(event.start, opt('timeFormat'))) +
				'</span>';
		}
		html +=
			"<span class='fc-event-title'>" +
			//add event title. Add a space to the title if it is empty to prevent event collapse
			(titleEscape(
				(event.title || '').replace(/\n/g, opt('returnSub')),
				event
			) || '&nbsp;') +
			'</span>' +
			'</div>';
		if (
			!event.allDay &&
			segment.isEnd &&
			isEventResizable(event) &&
			!event.isUnavailable
		) {
			html +=
				"<div class='ui-resizable-handle ui-resizable-" +
				(isRTL ? 'w' : 'e') +
				"'>" +
				'&nbsp;&nbsp;&nbsp;' + // makes hit area a lot better for IE6/7
				'</div>';
		}
		html += '</' + (url ? 'a' : 'div') + '>';

		// TODO:
		// When these elements are initially rendered, they will be briefly visibile on the screen,
		// even though their widths/heights are not set.
		// SOLUTION: initially set them as visibility:hidden ?

		return html;
	}

	// Associate each segment (an object) with an element (a jQuery object),
	// by setting each `segment.element`.
	// Run each element through the `eventRender` filter, which allows developers to
	// modify an existing element, supply a new one, or cancel rendering.
	function resolveElements(segments, elements) {
		for (var i = 0; i < segments.length; i++) {
			var segment = segments[i];
			var event = segment.event;
			var element = elements.eq(i);

			// call the trigger with the original element
			var triggerRes = trigger('eventRender', event, event, element);

			if (triggerRes === false) {
				// if `false`, remove the event from the DOM and don't assign it to `segment.event`
				element.remove();
			} else {
				if (triggerRes && triggerRes !== true) {
					// the trigger returned a new element, but not `true` (which means keep the existing element)

					// re-assign the important CSS dimension properties that were already assigned in `buildHTMLForSegment`
					triggerRes = $(triggerRes).css({
						position: 'absolute',
						left: segment.left,
					});

					element.replaceWith(triggerRes);
					element = triggerRes;
				}

				segment.element = element;
			}
		}
	}

	/* Top-coordinate Methods
	-------------------------------------------------------------------------------------------------*/

	// Sets the "top" CSS property for each element.
	// If `doRowHeights` is `true`, also sets each row's first cell to an explicit height,
	// so that if elements vertically overflow, the cell expands vertically to compensate.
	function setVerticals(segments, doRowHeights) {
		var rowContentHeights = calculateVerticals(segments); // also sets segment.top
		var rowContentElements = getRowContentElements(); // returns 1 inner div per row
		var rowContentTops = [];
		var i;
		var containerElements = $('td.fc-row-label');

		// Set each row's height by setting height of first inner div
		if (doRowHeights) {
			for (i = 0; i < rowContentElements.length; i++) {
				rowContentElements[i].height(rowContentHeights[i]);
			}
		}

		// Get each row's top, relative to the views's origin.
		// Important to do this after setting each row's height.
		for (i = 0; i < rowContentElements.length; i++) {
			rowContentTops.push(rowContentElements[i].position().top);
		}

		// Set each segment element's CSS "top" property.
		// Each segment object has a "top" property, which is relative to the row's top, but...
		segmentElementEach(segments, function (segment, element) {
			if (segment.event.isUnavailable) {
				var row = segment.row;
				var minHeight = containerElements.get(row).clientHeight;
				var rowHeight = rowContentHeights[row];
				element.css('top', rowContentTops[segment.row] - 2);
				element.css('height', Math.max(minHeight, rowHeight) + 'px');
			} else {
				element.css(
					'top',
					rowContentTops[segment.row] + segment.top // ...now, relative to views's origin
				);
			}
		});
	}

	// Calculate the "top" coordinate for each segment, relative to the "top" of the row.
	// Also, return an array that contains the "content" height for each row
	// (the height displaced by the vertically stacked events in the row).
	// Requires segments to have their `outerHeight` property already set.
	function calculateVerticals(segments) {
		var rowCnt = getRowCnt();
		var colCnt = getColCnt();
		var rowContentHeights = []; // content height for each row
		var segmentRows = buildSegmentRows(segments); // an array of segment arrays, one for each row
		var colI;

		for (var rowI = 0; rowI < rowCnt; rowI++) {
			var segmentRow = segmentRows[rowI];

			// an array of running total heights for each column.
			// initialize with all zeros.
			var colHeights = [];
			for (colI = 0; colI < colCnt; colI++) {
				colHeights.push(0);
			}

			// loop through every segment
			for (var segmentI = 0; segmentI < segmentRow.length; segmentI++) {
				var segment = segmentRow[segmentI];

				// find the segment's top coordinate by looking at the max height
				// of all the columns the segment will be in.
				segment.top = arrayMax(
					colHeights.slice(
						segment.leftCol,
						segment.rightCol + 1 // make exclusive for slice
					)
				);

				// adjust the columns to account for the segment's height
				for (colI = segment.leftCol; colI <= segment.rightCol; colI++) {
					colHeights[colI] = segment.top + segment.outerHeight;
				}
			}

			rowContentHeights.push(arrayMax(colHeights));
		}

		return rowContentHeights;
	}

	// Build an array of segment arrays, each representing the segments that will
	// be in a row of the grid, sorted by which event should be closest to the top.
	function buildSegmentRows(segments) {
		var rowCnt = getRowCnt();
		var segmentRows = [];
		var segmentI;
		var segment;
		var rowI;
		var unavailableSegments = [];

		// group segments by row
		for (segmentI = 0; segmentI < segments.length; segmentI++) {
			segment = segments[segmentI];

			if (segment.event.isUnavailable) {
				unavailableSegments.push(segment);
				continue;
			}

			rowI = segment.row;
			if (segment.element) {
				// was rendered?
				if (segmentRows[rowI]) {
					// already other segments. append to array
					segmentRows[rowI].push(segment);
				} else {
					// first segment in row. create new array
					segmentRows[rowI] = [segment];
				}
			}
		}

		// sort each row
		for (rowI = 0; rowI < rowCnt; rowI++) {
			segmentRows[rowI] = sortSegmentRow(
				segmentRows[rowI] || [] // guarantee an array, even if no segments
			);
		}

		// add unavailable items back in
		// console.log('add', unavailableSegments)
		// for (segmentI = 0; segmentI < unavailableSegments.length; segmentI++) {
		// 	segment = unavailableSegments[segmentI];
		// 	rowI = segment.row;
		// 	segmentRows[rowI].push(segment);
		// }

		return segmentRows;
	}

	// Sort an array of segments according to which segment should appear closest to the top
	function sortSegmentRow(segments) {
		var sortedSegments = [];

		// build the subrow array
		var subrows = buildSegmentSubrows(segments);

		// flatten it
		for (var i = 0; i < subrows.length; i++) {
			sortedSegments.push.apply(sortedSegments, subrows[i]); // append an array to an array
		}

		return sortedSegments;
	}

	// Take an array of segments, which are all assumed to be in the same row,
	// and sort into subrows.
	function buildSegmentSubrows(segments) {
		// Give preference to elements with certain criteria, so they have
		// a chance to be closer to the top.
		segments.sort(compareDaySegments);

		var subrows = [];
		for (var i = 0; i < segments.length; i++) {
			var segment = segments[i];

			// loop through subrows, starting with the topmost, until the segment
			// doesn't collide with other segments.
			for (var j = 0; j < subrows.length; j++) {
				if (
					!isDaySegmentCollision(segment, subrows[j]) ||
					segment.event.isUnavailable
				) {
					break;
				}
			}
			// `j` now holds the desired subrow index
			if (subrows[j]) {
				subrows[j].push(segment);
			} else {
				subrows[j] = [segment];
			}
		}

		return subrows;
	}

	// Return an array of jQuery objects for the placeholder content containers of each row.
	// The content containers don't actually contain anything, but their dimensions should match
	// the events that are overlaid on top.
	function getRowContentElements() {
		var i;
		var rowCnt = getRowCnt();
		var rowDivs = [];
		for (i = 0; i < rowCnt; i++) {
			rowDivs[i] = allDayRow(i).find('div.fc-day-content > div');
		}
		return rowDivs;
	}

	/* Mouse Handlers
	---------------------------------------------------------------------------------------------------*/
	// TODO: better documentation!

	function attachHandlers(segments, modifiedEventId) {
		var segmentContainer = getDaySegmentContainer();

		segmentElementEach(segments, function (segment, element, i) {
			var event = segment.event;
			if (event._id === modifiedEventId) {
				bindDaySeg(event, element, segment);
			} else {
				element[0]._fci = i; // for lazySegBind
			}
		});

		lazySegBind(segmentContainer, segments, bindDaySeg);
	}

	function bindDaySeg(event, eventElement, segment) {
		if (isEventDraggable(event)) {
			t.draggableDayEvent(event, eventElement, segment); // use `t` so subclasses can override
		} else {
			t.nonDraggableDayEvent(event, eventElement, segment);
		}

		if (!event.allDay && isEventResizable(event)) {
			t.resizableDayEvent(event, eventElement, segment); // use `t` so subclasses can override
		}

		// attach all other handlers.
		// needs to be after, because resizableDayEvent might stopImmediatePropagation on click
		eventElementHandlers(event, eventElement);
	}

	function draggableDayEvent(event, eventElement) {
		var hoverListener = getHoverListener();
		var minuteDelta;
		var cellDelta;
		var eventStart;
		var eventEnd;

		var getResources = t.getResources;
		var originalEventResource;
		var eventResource;
		var newEventResource = event.resource.slice(0); //Clone the resource array

		var eventPosition;
		var isInBounds;

		var container = $('.calendar-scroll');
		var containerPosition = container[0].getBoundingClientRect();
		var contentContainer = container[0].querySelector(
			'.fc-event-container'
		);
		var isAltViewDrag;
		var altViewHover;
		var startScroll;
		var isClone;

		eventElement.draggable({
			delay: 50,
			opacity: opt('dragOpacity'),
			revertDuration: opt('dragRevertDuration'),
			helper: 'clone',
			start: function (ev, ui) {
				if (isPreventEventMovement(event)) {
					eventElement.css('opacity', 1);
					return false;
				}

				isClone = manageClone(ev, ui, event);
				if (isClone) {
					ui.helper.append(
						'<div class="add-event-button add-event-button-small"></div>'
					);
				} else {
					eventElement.css('visibility', 'hidden');
				}

				startScroll = container.scrollTop();
				calendar.reportAltViewDragStart(ev, ui); // For dragging from calendar into unscheduled
				trigger('eventDragStart', eventElement, event, ev, ui);
				hideEvents(event, eventElement);
				hoverListener.start(
					function (cell, origCell, rowDelta, colDelta) {
						isClone = manageClone(ev, ui, event);
						eventElement.draggable(
							'option',
							'revert',
							!cell || (!rowDelta && !colDelta && !isClone)
						);
						clearOverlays();
						if (cell) {
							var origDateCell = {row: 0, col: origCell.col};
							var dateCell = {row: 0, col: cell.col};

							var origCellDate = cellToTime(origDateCell);
							var cellDate = cellToTime(dateCell);
							isInBounds = true;
							cellDelta = cell - origCell;
							minuteDelta = calendar
								.unzoneDate(cellDate)
								.diff(
									calendar.unzoneDate(origCellDate),
									'minutes'
								);
							eventStart = calendar.rezoneDate(
								calendar
									.unzoneDate(event.start)
									.add(minuteDelta, 'minutes')
							);
							eventEnd = calendar.rezoneDate(
								calendar
									.unzoneDate(getEventEnd(event))
									.add(minuteDelta, 'minutes')
							);
							originalEventResource =
								getResources(false)[origCell.row];
							eventResource = getResources(false)[cell.row];
							if (eventEnd.isBefore(eventStart)) {
								eventEnd = eventStart.clone();
							}
							renderDayOverlay(eventStart, eventEnd, true, [
								eventResource.name,
							]);
						} else {
							isInBounds = false;
							eventResource = undefined;
							minuteDelta = 0;
						}
					},
					ev,
					'drag'
				);
			},
			//Drag function was added as a fix for container scrolling issues while dragging
			drag: function (ev, ui) {
				isClone = manageClone(ev, ui, event);
				isAltViewDrag = calendar.reportAltViewDrag(
					ev,
					ui,
					contentContainer,
					event,
					isClone
				);
				if (isAltViewDrag) {
					ui.position.top =
						ui.position.top + containerPosition.top - startScroll;
					ui.position.left =
						ui.position.left + containerPosition.left;
					if (!altViewHover) {
						// document.body.append(ui.helper[0]);
						altViewHover = true;
						eventElement.draggable('option', 'revert', false);
						eventElement.draggable('option', 'scroll', false);
					}
				} else {
					eventPosition =
						ui.position.top - startScroll + container.scrollTop();
					ui.position.top = eventPosition;
					if (altViewHover) {
						altViewHover = false;
						eventElement.draggable('option', 'scroll', true);
					}
				}
				trigger('eventDrag', eventElement, event, ev, ui);
			},
			stop: function (ev, ui) {
				calendar.reportAltViewDragStop(ui, isAltViewDrag); // For dragging from calendar to unscheduled

				isClone = manageClone(ev, ui, event);
				if (!isClone) {
					eventElement.css('visibility', 'visible');
				}

				if (eventResource) {
					//Remove original event resource that we are dragging from and any duplicates
					for (var i = event.resource.length; i >= 0; i--) {
						if (
							event.resource[i] === originalEventResource.name ||
							event.resource[i] === eventResource.name
						) {
							newEventResource.splice(i, 1);
						}
					}
					//Add new event resource that we are dragging to
					newEventResource.push(eventResource.name);
				}
				hoverListener.stop();
				clearOverlays();
				trigger('eventDragStop', eventElement, event, ev, ui);
				if (
					isAltViewDrag ||
					minuteDelta ||
					cellDelta ||
					(eventResource &&
						originalEventResource.name !== eventResource.name) ||
					(isClone && isInBounds)
				) {
					eventDrop(
						this, // el
						event,
						eventStart,
						ev,
						ui,
						{value: newEventResource, field: 'resource'},
						isClone,
						isAltViewDrag
					);
				} else {
					eventElement.css('filter', ''); // clear IE opacity side-effects
					showEvents(event, eventElement);

					eventCancelDrop(this, event, ev, ui);
				}
			},
		});
	}

	//What to do if the event can't be dragged
	function nonDraggableDayEvent(event, eventElement) {
		eventElement.draggable({
			start: function (ev, ui) {
				trigger('eventNoDrag', eventElement, event, ev, ui);
				return false;
			},
		});
	}

	function resizableDayEvent(event, element, segment) {
		var isResizing = false;
		var getResources = t.getResources;

		var columnDuration = moment.duration(opt('slotDuration')).asMinutes();
		const handles = element.find('.ui-resizable-handle'); // TODO: stop using this class because we aren't using jqui for this

		// TODO: look into using jquery-ui mouse widget for this stuff
		//disableTextSelection(element); // prevent native <a> selection for IE
		element
			.mousedown(function (ev) {
				// prevent native <a> selection for others
				ev.preventDefault();
			})
			.click(function (ev) {
				if (isResizing) {
					ev.preventDefault(); // prevent link from being visited (only method that worked in IE6)
					ev.stopImmediatePropagation(); // prevent fullcalendar eventClick handler from being called
					// (eventElementHandlers needs to be bound after resizableDayEvent)
				}
			});

		handles.mousedown(function (ev) {
			const direction = ev.currentTarget.classList.contains(
				'ui-resizable-w'
			)
				? 'w'
				: 'e';
			if (isPreventEventMovement(event)) {
				return false;
			}

			if (ev.which != 1) {
				return; // needs to be left mouse button
			}
			isResizing = true;
			var hoverListener = getHoverListener();
			var elementTop = element.css('top');
			var minuteDelta;
			let eventStart = event.start.clone();
			var eventEnd = getEventEnd(event);
			var helpers;
			var eventCopy = $.extend({}, event);
			var minCellOffset = dateToTimeOffset(
				direction === 'e'
					? eventStart
					: eventEnd.clone().add(-columnDuration, 'minutes'),
				columnDuration
			);
			clearSelection();
			$('body')
				.css('cursor', direction + '-resize')
				.one('mouseup', mouseup);
			trigger('eventResizeStart', this, event, ev);
			hoverListener.start(function (cell, origCell) {
				if (cell) {
					const rangeType = 'minutes';
					var origCellOffset = origCell.col;
					var cellOffset = cell.col;

					// don't let resizing move earlier than start date cell
					cellOffset =
						direction === 'e'
							? Math.max(cellOffset, minCellOffset)
							: Math.min(cellOffset, minCellOffset);

					minuteDelta = Math.floor(
						(cellOffset - origCellOffset) * columnDuration
					);
					// assumed to already have a stripped time
					if (direction === 'w') {
						eventStart = event.start
							.clone()
							.add(minuteDelta, rangeType);
					} else {
						eventEnd = getEventEnd(event).add(
							minuteDelta,
							rangeType
						);
					}

					if (minuteDelta) {
						eventCopy.start = eventStart;
						eventCopy.end = eventEnd;
						var oldHelpers = helpers;
						helpers = renderTempDayEvent(
							eventCopy,
							segment.row,
							elementTop
						);
						helpers = $(helpers); // turn array into a jQuery object
						helpers.find('*').css('cursor', direction + '-resize');
						if (oldHelpers) {
							oldHelpers.remove();
						}
						hideEvents(event);
					} else {
						if (helpers) {
							showEvents(event);
							helpers.remove();
							helpers = null;
						}
					}
					clearOverlays();

					// Render day overlay not desirable here
					// Highlighting on resize doesn't help anything so disabled for now
					// renderDayOverlay(
					// 	// coordinate grid already rebuilt with hoverListener.start()
					// 	event.start,
					// 	eventEnd,
					// 	false,
					// 	[eventResource.name]
					// 	// TODO: instead of calling renderDayOverlay() with dates,
					// 	// call _renderDayOverlay (or whatever) with cell offsets.
					// );
				}
			}, ev);

			function mouseup(ev) {
				trigger('eventResizeStop', this, event, ev);
				$('body').css('cursor', '');
				hoverListener.stop();
				clearOverlays();

				if (minuteDelta) {
					eventResize(
						this, // el
						event,
						eventEnd,
						ev,
						null,
						eventStart
					);
					// event redraw will clear helpers
				}
				// otherwise, the drag handler already restored the old events

				setTimeout(function () {
					// make this happen after the element's click event
					isResizing = false;
				}, 0);
			}
		});
	}
}
